import linkifyHtml from "linkifyjs/lib/linkify-html";

app.provider("Backend", function () {
    this.processDeletionInProgress = false;

    this.setBackendUrl = function (url) {
        this.backend_url = url;
    };

    function BackendClient($http, $httpParamSerializer, $q, backend_url) {
        this.backend_url = backend_url;

        this.setTokenJwt = function (token) {
            this.token_jwt = token;
        };

        this.newFile = function (service_id) {
            // TODO: make it like getFile, as the return structure is totally different
            var url = this.backend_url + "files" + (location.search || "");
            return $http.post(url, {"service_id": service_id});
        };

        this.getFile = function (file_id) {
            var url = this.backend_url + "files/" + file_id;
            return $http.get(url).then(function (response) {
                var ret = response.data;
                return ret;
            })
        };

        this.updateFile = function (file_id, form_type, data, ignore_formio_validation) {
            var url = this.backend_url + "files/" + file_id + '/data/' + form_type;
            if (ignore_formio_validation) {
                url += "?ignore_formio_validation=1";
            }
            return $http.put(url, {"data_content": JSON.stringify(data)});
        };

        this.patchFile = function (file_id, data) {
            var url = this.backend_url + "files/" + file_id;
            return $http.patch(url, data);
        };

        this.updateProcess = function (process_id, role_name, form_type, data) {
            var url = this.backend_url + "process/" + process_id + '/role-forms/' + role_name + '/' + form_type;
            return $http.patch(url, {"data": data});
        };

        this.deleteFile = function (file_id) {
            var url = this.backend_url + "files/" + file_id;
            return $http.delete(url);
        };

        this.deleteProcess = function (processInstanceId) {
            if (!GLOBALS.processDeletionInProgress) {
                GLOBALS.processDeletionInProgress = true;
                const url = this.backend_url + "delete-process/" + processInstanceId;

                return $http.delete(url);
            }
        };

        this.startFileProcess = function (file_id, data) {
            var url = this.backend_url + "files/" + file_id + '/start_process';
            return $http.post(url, {"data_content": JSON.stringify(data)});
        };

        this.getRoleProcessVariables = function (file, role, forceHistory = false) {
            var active_task;
            var roleId = role && typeof role === "object" ? role.id : role;
            let url;
            if (forceHistory) {
                url = this.backend_url + "process/" + file.id + "/historical-camunda-variables";
            } else {
                if (!file.ended) {
                    file.tasks.some(function (task) {
                        if (task.status === 'filepending' && (task.camundaName === roleId || !roleId)) {
                            active_task = task;
                            if (!roleId) {
                                roleId = task.camundaName;
                            }
                            return true;
                        }
                    });
                }

                url = active_task ? this.backend_url + "process/" + file.id + "/role/" + roleId + "/camunda-variables/" + active_task.id :
                    this.backend_url + "process/" + file.id + "/historical-camunda-variables";
            }

            return $http.get(url).then(function (response) {
                var ret = {};

                if (response.data.form_url) {
                    ret.form_url = response.data.form_url;
                }
                if (response.data.form_submit_url) {
                    ret.form_submit_url = response.data.form_submit_url;
                }

                if (response.data.variables) {
                    ret.variables = JSON.parse(response.data.variables.json.value);
                } else {
                    ret.variables = response.data;//TODO: clearly room for improvement here, endpoints seem to be inconsistent about result structure
                }

                return ret;
            });

        };

        this.getProcessCamundaPreviewForms = function (process_id) {
            var url = this.backend_url + "process/" + process_id + "/camunda-preview-forms";
            return $http.get(url);
        };

        this.getFileList = function () {
            var url = this.backend_url + "files";
            return $http.get(url).then(function (response) {
                var ret = response.data;
                return ret;
            })
        };
        this.getFileListInProcessPage = function (service_id, role, params) {
            var url = this.backend_url + "process/inprocess";
            if (service_id) url += "/" + service_id;
            if (role) url += "/" + role;

            var serializedParams = $httpParamSerializer(params);
            if (serializedParams.length > 0) {
                url = url + "?" + serializedParams;
            }
            return $http.get(url).then(function (response) {
                var ret = response.data;
                return ret;
            })
        };

        this.getProcessDocuments = function (process_id) {
            var url = this.backend_url + "process/" + process_id + '/document';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getFileCertificates = function (file_id) {
            var url = this.backend_url + "files/" + file_id + '/certificate';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.setDocumentName = function (doc, name) {
            var url = doc.url + '/name';
            return $http.post(url, {'name_of_document': name}).then(function (response) {
                return response.data;
            });
        };

        this.setCertificateName = function (doc, name, certId) {
            var url = doc.url + '/name';
            return $http.post(url, {'name_of_certificate': name, 'certificate_id': certId}).then(function (response) {
                return response.data;
            });
        };

        this.getProcess = function (process_id) {
            var url = this.backend_url + "process/" + process_id;
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        /**
         * Get certificates for admin user
         * @param process_id
         * @returns {*}
         */
        this.getProcessCertificates = function (process_id) {
            var url = this.backend_url + "process/" + process_id + "/certificate";
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        /**
         * Get certificates for normal user
         * @param process_id
         * @returns {*}
         */
        this.getUserProcessCertificates = function (process_id) {
            // this does not seem to work well... var url = this.backend_url + "certificates?process_id=" + process_id;
            var url = this.backend_url + "certificates?file__process_instance_id=" + process_id;
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.updateForm = function (url, data) {
            return $http.put(url, data).then(function (response) {
            });
        };

        this.getFormioForm = function (form_id/*, opts */) {
            var opts = Object(arguments[1]);
            var url = this.backend_url + "files/form/" + form_id;
            if (opts && opts.process_id) {
                url += "?process_id=" + opts.process_id;
            }
            return $http.get(url);
        };

        this.getFormDefinition = function (service_id, form_type, is_draft) {
            var url = this.backend_url + 'services/' + service_id + '/form-type/' + form_type;
            if (is_draft) {
                url += '/draft';
            }
            var headers = {};
            if (this.token_jwt) {
                headers['Authorization'] = 'Bearer ' + this.token_jwt;
            }
            return $http.get(url, {'headers': headers});
        };

        this.getUserFiles = function (/* opts */) {
            var opts = Object(arguments[0]);
            if (opts.order_by && (opts.order_by !== 'created_at' && opts.order_by !== '-created_at')) {
                opts.order_by += ',-created_at';
            }
            if (!opts.page) {
                opts.page = 1;
            }
            var url = '/api/v1/current-user-files/?page=' + opts.page;
            if (opts.businessEntity) {
                url += "&business_entity=" + encodeURIComponent(opts.businessEntity);
            }
            if (opts.order_by) {
                url += '&ordering=' + opts.order_by;
            }
            if (opts.state) {
                url += '&state=' + opts.state;
            }
            if (opts.service) {
                url += '&service=' + encodeURIComponent(opts.service);
            }
            if (opts.company) {
                url += '&company=' + encodeURIComponent(opts.company);
            }
            return this.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getUserProcesses = function () {
            var url = this.backend_url + 'process/user';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getUserDocuments = function () {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'documents/user';
            var params = Object.assign({}, opts);

            if (opts.page) {
                params.page = opts.page;
            } else {
                params.page = 1;
            }
            if (opts.ordering) {
                params.ordering = opts.ordering;
            }
            var urlParams = this.objectToUrlParams(params);
            url += urlParams ? '?' + urlParams : '';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getUserUnifiedDocuments = function () {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'documents/user/certs';
            var params = Object.assign({}, opts);

            if (opts.page) {
                params.page = opts.page;
            } else {
                params.page = 1;
            }
            if (opts.ordering) {
                params.ordering = opts.ordering;
            }
            var urlParams = this.objectToUrlParams(params);
            url += urlParams ? '?' + urlParams : '';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getUserValidDocuments = function () {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'documents/user/valid';
            var params = Object.assign({}, opts);

            if (opts.page) {
                params.page = opts.page;
            } else {
                params.page = 1;
            }
            if (opts.ordering) {
                params.ordering = opts.ordering;
            }
            var urlParams = this.objectToUrlParams(params);
            url += urlParams ? '?' + urlParams : '';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getUserCertificates = function () {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'certificates/user';
            var params = Object.assign({}, opts);

            if (opts.page) {
                params.page = opts.page;
            } else {
                params.page = 1;
            }
            if (opts.ordering) {
                params.ordering = opts.ordering;
            }
            var urlParams = this.objectToUrlParams(params);
            url += urlParams ? '?' + urlParams : '';
            return this.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getRegistrationStatuses = function () {
            var url = this.backend_url + 'services/all_processes';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        };

        this.getServiceRoles = function (service_id, status) {
            var url = this.backend_url + 'services/' + service_id + '/roles?status=' + status;
            return $http.get(url).then(function (response) {
                return response.data;
            })
        };

        this.getPrintFileData = function (service_id, file_id) {
            var backend_url = this.backend_url;
            var url = backend_url + 'services/' + service_id + '/print/' + file_id;

            return $http.get(url).then(function (response) {
                var data = response.data;
                data['url'] = backend_url + data['url'];
                return data;
            });
        };
        this.getFileArchiveURL = function (process_id) {
            var url = this.backend_url + "process/" + process_id + '/download_archive';
            return url;
        };

        this.generateCertificate = function (path, data) {
            var url = this.backend_url + 'process/generate-cert';

            var post_data = {
                'path': path,
                'data': data
            }

            return $http.post(url, post_data).then(function (response) {
                return response.data;
            });
        };

        this.getUserAlerts = function () {
            var url = this.backend_url + 'user/alerts';

            return this.get(url).then(function (response) {
                response.data.forEach((each) => {
                    each.content = linkifyHtml(each.content, {target: '_blank'});
                });

                return response.data;
            })
        };

        this.hideAlert = function (alert_id) {
            if (!alert_id) {
                return false;
            }
            var url = this.backend_url + 'user/alerts/' + alert_id;

            return $http.delete(url);
        };

        this.markMessageAsRead = function (message_id) {
            if (!message_id) {
                return false;
            }
            var url = this.backend_url + 'user/messages/' + message_id;

            return $http.patch(url, {is_seen: true});
        };

        this.getUnSeenMessagesCount = function () {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'user/messages/get_unseen_count';
            var urlParams = this.objectToUrlParams(opts);
            url += urlParams ? '?' + urlParams : '';

            return $http.get(url);
        };

        this.objectToUrlParams = function (obj) {
            if (!obj) return '';
            if (typeof obj !== 'object') return '';
            return Object.keys(obj).map(function (key) {
                return key + '=' + encodeURIComponent(obj[key]);
            }).join("&");
        }

        this.getUserMessages = function (/**/) {
            var opts = Object(arguments[0]);
            var url = this.backend_url + 'user/messages';
            var params = Object.assign({}, opts);

            if (opts.page) {
                params.page = opts.page;
            } else {
                params.page = 1;
            }
            if (opts.ordering) {
                params.ordering = opts.ordering;
            }
            var urlParams = this.objectToUrlParams(params);
            url += urlParams ? '?' + urlParams : '';

            return this.get(url).then(function (response) {
                return response.data;
            })
        };

        this.deleteMessage = function (id) {
            if (!id) {
                return false;
            }
            var url = this.backend_url + 'user/messages/' + id;
            return $http.delete(url);
        }

        this.requestLoaderVisibility = function (status) {
            this.hiddenRequests = status;
        };

        this.get = function (url) {
            var opts = {};
            if (this.hiddenRequests === false) {
                opts['hideRequest'] = true;
            }

            return $http.get(url, opts);
        }

        this.log = function (data) {
            var url = this.backend_url + 'log';
            return $http.post(url, data).then(function (response) {
                return response.data;
            });
        }

        this.startPayment = function (data) {
            var url = this.backend_url + 'payment_transaction';
            if (data.providerCode) {
                url += "/" + data.providerCode;
            } else {
                url += "/" + 'iveri';
            }

            return $http.post(url, data);
        }

        this.getTransactions = function (params) {
            var url = this.backend_url + 'payment_transaction';
            var serializedParams = $httpParamSerializer(params);
            if (serializedParams.length > 0) {
                url = url + "?" + serializedParams;
            }
            return $http.get(url);
        }

        this.postFormOverviewData = function (data) {
            var opts = Object(arguments[1]);
            var version = opts.version || "";
            var url = this.backend_url + 'form-preview' + version + '/1 ';
            return $http.post(url, data);
        }

        this.getFilteredForm = function (form_id, data) {
            var url = this.backend_url + "filter-form-schema/" + form_id;
            return $http.put(url, {data: data});
        };

        this.getBusinessEntities = function (page_number, order_by, skip_files) {
            if (!order_by) {
                order_by = 'created_at';
            }
            var url = this.backend_url + 'businessentity?ordering=' + order_by;
            if (page_number) {
                url += "&page=" + page_number;
            } else {
                url += "&super_size_me=100000";
            }
            if (skip_files) {
                url += "&skip_files=1";
            }
            return this.get(url).then(function (response) {
                return response.data;
            });
        }
        this.getBusinessEntity = function (id) {
            var url = this.backend_url + 'businessentity/' + id;
            return this.get(url).then(function (response) {
                return response.data;
            });
        }
        this.addBusinessEntity = function (data) {
            var url = this.backend_url + 'businessentity';
            return $http.post(url, data);
        }

        this.updateBusinessentity = function (businessEntity) {
            var url = this.backend_url + 'businessentity/' + businessEntity.business_entity_id;
            //var url = businessEntity.url;
            return $http.put(url, businessEntity);
        }

        this.deleteBusinessEntity = function (id) {
            var url = this.backend_url + 'businessentity/' + id;
            return $http.delete(url);
        }

        this.getServiceCategories = function () {
            var url = this.backend_url + 'services/categories';
            return this.get(url).then(function (response) {
                return response.data;
            });
        }

        this.getServices = function (/* opts */) {
            var url = this.backend_url + 'services';
            var opts = Object(arguments[0]);
            if (opts.isPartB) {
                url += "?isPartB=1";
            }
            return this.get(url).then(function (response) {
                return response.data;
            });
        }

        this.restartProcess = function (process_definition_id, data) {
            var url = this.backend_url + 'restart-process/' + process_definition_id;
            return $http.post(url, {data: data}).then(function (response) {
                return response.data;
            });
        }
    }

    this.$get = ["$http", "$httpParamSerializer", "$q", "SiteConfiguration", function unicornLauncherFactory($http, $httpParamSerializer, $q, SiteConfiguration) {
        return new BackendClient($http, $httpParamSerializer, $q, SiteConfiguration.getBackendUrl());
    }];
});
